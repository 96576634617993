import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Status } from '@paljs/ui/types';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import './style.css';

import React, { useState, useEffect } from 'react';
import SEO from '../../components/SEO';
import { getFamilyInfo } from '../../actions/familyTreeAction';
import maleAvatar from '../matrimonials/defaultAvatars/maleAvatar.webp';
import femaleAvatar from '../matrimonials/defaultAvatars/femaleAvatar.jpg';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../loader';

import { mergeFamilyDetails, lookup_child } from '../../helpers/merge_family_test';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import 'react-sortable-tree/style.css';
// import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import SortableTree, { toggleExpandedForAll, getFlatDataFromTree } from 'react-sortable-tree';
import _ from 'lodash';
import { useLocation } from '@reach/router';
import NodeDetails from './node-details';
import AddSuggestedRelations from './add-suggested-relation';
import { getLoggedInUser } from '../../actions/authAction';
import { mdiAccountStar } from '@mdi/js';
import Icon from '@mdi/react';

const getNodeKey = ({ node }) => node.uid;

const getPathsForEachNode = (nodeData, pathsData = {}, path = []) => {
  // let path = [];
  path.push(nodeData.uid);
  pathsData[nodeData.uid] = _.cloneDeep(path);
  let children = nodeData.children || [];
  for (let i = 0; i < children.length; ++i) {
    getPathsForEachNode(children[i], pathsData, path);
  }
  path.pop();
  return pathsData;
};

export default function index() {
  // const [data, setData] = useState('')
  const [treeData, setTreeData] = useState([]);
  // const [newTreeData, setNewTreeData] = useState([]);
  const [isLoading, showLoader] = useState(true);
  // const [searchString, setSearchString] = useState('');
  const [searchObject, setSearchObject] = useState({ searchString: '', searchPeehar: null });
  const [currNode, setCurrNode] = useState('');
  const [currPath, setCurrPath] = useState('');

  const [selectedNode, setSelectedNode] = useState('');
  const [selectedPath, setSelectedPath] = useState(null);

  //these States are used for modal only
  const [isOpen, setOpen] = useState(false);
  const [nodeToBeUpdated, setNodeToBeUpdated] = useState();
  const [suggestedChange, setSuggestedChange] = useState('');
  const [suggestedNodeId, setSuggestedNodeId] = useState('');
  const [nodeToBeAdded, setNodeToBeAdded] = useState();
  const [suggestedRelationObj, setSuggestedRelationObj] = useState({ relation: '', gender: '' });

  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const nodeIdfromURL = params.get('idOfUser');

  // const nodeData = {
  //   action: 'get_family_details',
  //   person_node_id: '1',
  //   mode: 'json',
  //   device_info: {
  //     appVersion: '0.6.7undefined',
  //   },
  // };

  useEffect(async () => {
    showLoader(true);
    const loggedInNode = getLoggedInUser();
    // const loggedInfo = { name: loggedInNode.name, nodeId: loggedInNode.graph_node_id };
    const node = nodeIdfromURL ?? loggedInNode?.graph_node_id;
    try {
      getFamilyDetails(node, [node]);
    } catch (error) {
      console.log(`Could not fetch family details of nodeId: ${node}\n Error: ${error}`);
      showLoader(false);
      toast.error(`Could not fetch family details of nodeId: ${node}\n Error: ${error}`);
    }
  }, []);
  const mergeInFinalTree = (finalMergedTreeData, familyToCheck) => {
    let isMerged = false;
    for (let i = 0; i < finalMergedTreeData.length; i++) {
      let existingFamilyData = finalMergedTreeData[i];
      if (existingFamilyData.family_id == familyToCheck.family_id) {
        // If merge is possible, then merge it
        let mergedRootNodesData = {};

        if (lookup_child(existingFamilyData, familyToCheck.uid)) {
          mergedRootNodesData = mergeFamilyDetails(existingFamilyData, familyToCheck, familyToCheck.uid);
        } else if (lookup_child(familyToCheck, existingFamilyData.uid)) {
          mergedRootNodesData = mergeFamilyDetails(familyToCheck, existingFamilyData, existingFamilyData.uid);
        }
        if (!_.isEmpty(mergedRootNodesData)) {
          finalMergedTreeData[i] = mergedRootNodesData;
          isMerged = true;
          break;
        }
      }
    }
    if (!isMerged) {
      finalMergedTreeData.push(familyToCheck);
    }
  };

  const mergeRootNodesIfNeeded = (treeDataWithAPIResult) => {
    let finalMergedTreeData = [treeDataWithAPIResult[0]];
    for (let i = 1; i < treeDataWithAPIResult.length; i++) {
      mergeInFinalTree(finalMergedTreeData, treeDataWithAPIResult[i]);
    }
    return finalMergedTreeData;

    // Old approach
    // For each node, get family_id and store in dict
    // let finalMergedTreeData = [treeDataWithAPIResult[0]];
    // let finalMergedTreeDataFamilyIdToIndex = {};
    // let finalMergedTreeIndex = 0;
    // let familyIdToIndex = {};

    //   const treeDataResponse = treeDataWithAPIResult[i];
    //   const familyId: string = treeDataResponse.family_id;

    //   if (familyId in familyIdToIndex) {
    //     // TODO: then we need merge
    //     let earlierTreeIndex = familyIdToIndex[familyId];
    //     let earlierTreeData = treeDataWithAPIResult[earlierTreeIndex];
    //     let mergedRootNodesData = {};
    //     if (lookup_child(earlierTreeData, treeDataResponse.uid)) {
    //       mergedRootNodesData = mergeFamilyDetails(earlierTreeData, treeDataResponse, treeDataResponse.uid);
    //     } else if (lookup_child(treeDataResponse, earlierTreeData.uid) {
    //       mergedRootNodesData = mergeFamilyDetails(treeDataResponse, earlierTreeData, earlierTreeData.uid);
    //     }
    //     finalMergedTreeData[finalMergedTreeDataFamilyIdToIndex[familyId]] = mergedRootNodesData;

    //   } else {
    //     finalMergedTreeData.push(treeDataResponse);
    //   }
    // }

    //   if (
    //     familyId in familyIdToIndex &&
    //     (lookup_child(earlierTreeData, treeDataResponse.uid) || lookup_child(treeDataResponse, earlierTreeData.uid))
    //   ) {
    //     // If there is already one..then merge these two, put into earlier index and skip this one
    //     // TODO: Put a check if merge is needed or not
    //     let mergedRootNodesData = {};
    //     if (lookup_child(earlierTreeData, treeDataResponse.uid)) {
    //       mergedRootNodesData = mergeFamilyDetails(earlierTreeData, treeDataResponse, treeDataResponse.uid);
    //     } else {
    //       mergedRootNodesData = mergeFamilyDetails(treeDataResponse, earlierTreeData, earlierTreeData.uid);
    //     }
    //     finalMergedTreeData[finalMergedTreeDataFamilyIdToIndex[familyId]] = mergedRootNodesData;
    //   } else if (earlierTreeData && lookup_child(treeDataResponse, earlierTreeData.uid)) {
    //     familyIdToIndex[familyId] = i;
    //     finalMergedTreeData[finalMergedTreeIndex] = treeDataResponse;
    //     finalMergedTreeDataFamilyIdToIndex[familyId] = finalMergedTreeIndex;
    //     finalMergedTreeIndex += 1;
    //   }
    // }
    // return finalMergedTreeData;
  };

  const getRootIndexInTree = (rootNodeUid: string) => {
    return _.findIndex(treeData, { uid: rootNodeUid });
  };

  // Pass -1 if clicked on spouse node, then a new family is added in tree (TODO: how to get spouse at first place in case of female)
  // TODO: or call getFamily two times (but better to do it in one call as you have all needed data, update spouse of selectedNde
  // and showFamily is appended)
  // Append may not work always as family may exist in one of the families (that will be decided if a common family_id comes from backend)
  // if same family id in backend, then try to see if it can be merged, if it can be then merge..else append
  const getFamilyDetails = async (nodeId: string, path, isSpouse = false) => {
    // Flatten the tree, get path of nodeId from it in case of spouse
    console.log('For nodeId: ' + nodeId + ', path: ' + path + ', Path first element: ' + path[0]);
    if (isSpouse) {
      let flattenedTreeData = getPathsForEachNode(treeData);
      console.log('flattenedTreeData', flattenedTreeData);
      path = flattenedTreeData[nodeId];
    }

    let indexInTree = -1;
    if (path && path.length > 0) {
      indexInTree = getRootIndexInTree(path[0]);
    }

    const familyInfoResponse = await getFamilyInfo(nodeId);
    let requestedFamilyJson = familyInfoResponse.result;
    let new_node_data = lookup_child(requestedFamilyJson, nodeId);
    setSelectedNode(new_node_data);
    setSelectedPath(path);

    requestedFamilyJson = { ...requestedFamilyJson, expanded: true };

    if (nodeId != requestedFamilyJson.id) {
      let child_lookup = lookup_child(requestedFamilyJson, nodeId);

      if (child_lookup) {
        //CASE 2: e.g. harchandram
        // requestJSON = parent of that node i.e. nirasangram
        // so first find harchandram in requestedJSON and then set its expanded = true
        const child_lookup_id = child_lookup.id;

        const children = requestedFamilyJson.children;
        for (let i = 0; i < children.length; ++i) {
          if (children[i].id == child_lookup_id) {
            children[i] = { ...children[i], expanded: true };
          }
        }
      }
    }

    // Each element in treeData array represents a family.
    let newTreeData = [];
    if (indexInTree != -1 && treeData) {
      const selectedFamilyExistingData = treeData[indexInTree];
      const selectedFamilyExistingDataCopy = cloneDeep(selectedFamilyExistingData);
      const mergeFamilyData = mergeFamilyDetails(selectedFamilyExistingDataCopy, requestedFamilyJson, nodeId);
      newTreeData = treeData.map((obj, i: Number) => {
        if (i == indexInTree) return mergeFamilyData;
        else return obj;
      });
    } else {
      newTreeData = _.map(treeData, (f) => {
        return f;
      });
      newTreeData.push(requestedFamilyJson);
    }

    let mergedRootNodesData = newTreeData;
    if (newTreeData.length > 1) {
      mergedRootNodesData = mergeRootNodesIfNeeded(newTreeData);
    }
    setTreeData(mergedRootNodesData);
    // toast.success('All Data have been fetched successfuly - node name');

    //treeData[indexInTree]

    showLoader(false);
  };

  const expandAndCollapse = (expanded: boolean) => {
    console.log(
      'toggle expand tree',
      toggleExpandedForAll({
        treeData,
        expanded: true,
      }),
    );
    setTreeData(
      toggleExpandedForAll({
        treeData,
        expanded,
      }),
    );
  };

  const selectThis = (node: React.SetStateAction<string>, path: React.SetStateAction<string>) => {
    setCurrNode(node);
    setCurrPath(path);
  };

  //TODO: save order after rearranging

  const canDrop = ({ nextParent, prevParent }) => {
    // console.log('node', node);
    // console.log('nextParent', nextParent);
    // console.log('prevParent', prevParent);
    if (nextParent == null && prevParent == null) return true;
    if ((nextParent == null && prevParent != null) || (prevParent == null && nextParent != null)) return false;
    if (nextParent.id !== prevParent.id) return false;
    return true;
  };

  const isSpouseFamilyAdded = (node) => {
    // search for spouse id
    if (node.spouse != null) {
      // check spouse family not added
      // by checking if the spouse is present in the tree

      const spouse_id = node.spouse.uid;
      for (let i = 0; i < treeData.length; ++i) {
        let parentData = treeData[i];
        let spouse_lookup = lookup_child(parentData, spouse_id);
        if (spouse_lookup) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const customSearchMethod = ({ node, searchQuery }) => {
    let { searchString, searchPeehar } = searchQuery;
    let return_value = false;

    if (searchPeehar) {
      return_value = searchPeehar && node.uid == searchPeehar;
      // setting searchPeehar to null because if you click on a node consecutively it will not work again because there is no change in value of searchObject hook.
    } else {
      return_value = (searchString && node.name == searchString) || (node.spouse && node.spouse.name == searchString);
    }

    if (return_value) {
      setSearchObject({ searchString: '', searchPeehar: null });
    }

    return return_value;
  };

  const isFatherAdded = (node) => {
    // search for spouse id

    if (node.spouse != null) {
      // check spouse family not added
      // by checking if the spouse is present in the tree

      const spouse_id = node.spouse.uid;
      for (let i = 0; i < treeData.length; ++i) {
        let parentData = treeData[i];
        if (parentData.id == spouse_id) {
          return false;
        }
      }
      return true;
    }
    return false;
  };
  const avatarStyles = {
    height: '20px',
    width: '20px',
    borderRadius: '50px',
    marginRight: '8px',
    marginTop: '3px',
  };

  const sortableTreeDiv = (
    <>
      {console.log('tree data', treeData)}
      <Row>
        <InputGroup fullWidth size="Small" status="Info">
          <Button
            fullWidth
            appearance="outline"
            shape={'Round'}
            size={'Small'}
            Status={'Info'}
            onClick={() => {
              expandAndCollapse(true);
            }}
          >
            Expand all
          </Button>
          <Button
            fullWidth
            appearance="outline"
            shape={'Round'}
            size={'Small'}
            Status={'Info'}
            onClick={() => {
              expandAndCollapse(false);
            }}
          >
            Collapse all
          </Button>
          &nbsp;&nbsp;&nbsp;
          {/* <input
            size="mini"
            placeholder="Search"
            value={searchObject.searchString}
            onChange={(event) => setSearchObject({ searchString: event.target.value, searchPeehar: null })}
          /> */}
        </InputGroup>
      </Row>
      <SortableTree
        treeData={treeData}
        onChange={(familyData: React.SetStateAction<never[]>) => setTreeData(familyData)}
        getNodeKey={getNodeKey}
        canDrop={canDrop}
        canDrag={false}
        searchMethod={customSearchMethod}
        searchQuery={searchObject}
        searchFocusOffset={0}
        generateNodeProps={({ node, path }) => {
          const getAvatar = (person) => person?.pic_url || (person?.gender === 'female' ? femaleAvatar : maleAvatar);
          return {
            title: (
              <>
                <p style={{ color: 'black' }}>
                  <Button
                    style={selectedNode.uid == node.uid ? { backgroundColor: '#B000D1' } : {}}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      getFamilyDetails(node.uid, path);
                    }}
                  >
                    {node.alive == 'dead' ? 'स्व. ' : ''}
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {node.is_mukhiya ? (
                        <Icon
                          path={mdiAccountStar}
                          style={{ marginRight: '5px', marginTop: '5px' }}
                          size={1}
                          color="gold"
                        />
                      ) : (
                        ''
                      )}
                      <img src={getAvatar(node)} style={avatarStyles} alt="Node Avatar" />
                      {node.name} &nbsp;
                      {node.born ? '(' + (moment().year() - moment(node.born).year()) + ')' : ''}
                      {node.mobile && !node.registered_mobile ? '📞' : ''} &nbsp;
                      {node.registered_mobile ? '📱' : ''}
                    </span>
                  </Button>
                  {node.spouse ? ' ~ ' : ''}
                  {node.spouse ? (
                    <>
                      <Button
                        style={selectedNode.uid == node.spouse.uid ? { backgroundColor: '#B000D1' } : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          getFamilyDetails(node.spouse.uid, path, true);
                        }}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          {node?.spouse?.is_mukhiya ? (
                            <Icon
                              path={mdiAccountStar}
                              style={{ marginRight: '5px', marginTop: '5px' }}
                              size={1}
                              color="gold"
                            />
                          ) : (
                            ''
                          )}
                          {node?.spouse && (
                            <img src={getAvatar(node.spouse)} style={avatarStyles} alt="Spouse Avatar" />
                          )}
                          {node.spouse.name}
                          &nbsp;
                          {node.spouse.born ? '(' + (moment().year() - moment(node.spouse.born).year()) + ')' : ''}
                          {node.spouse.mobile && !node.spouse.registered_mobile ? '📞' : ''} &nbsp;
                          {node.spouse.registered_mobile ? '📱' : ''}
                        </span>
                      </Button>
                      {!isFatherAdded(node) && node.metadata?.spouse && selectedNode.uid == node.spouse.uid ? (
                        <span>
                          {' ~ '}
                          <Button
                            style={{ backgroundColor: '#B0D0D1', color: 'black' }}
                            onClick={() => {
                              console.log('clicked ');
                              const relationObj = { relation: 'parent', gender: 'male' };
                              addRelationFromSuggestedData(
                                node.spouse.uid,
                                { name: node.metadata?.spouse?.father },
                                'ADD_NODE',
                                relationObj,
                              );
                            }}
                          >
                            ADD {node.spouse.name}'s Father, {node.metadata.spouse.father}
                          </Button>
                        </span>
                      ) : (
                        ''
                      )}
                    </>
                  ) : node.metadata?.spouse && selectedNode.uid == node.uid ? (
                    <>
                      <span> ~ </span>
                      <Button
                        style={{ backgroundColor: '#B0D0D1', color: 'black' }}
                        onClick={() => {
                          console.log('clicked ');
                          const relationObj = { relation: 'spouse', gender: node.gender == 'male' ? 'female' : 'male' };
                          addRelationFromSuggestedData(node.uid, node.metadata?.spouse, 'ADD_NODE', relationObj);
                        }}
                      >
                        Add {node.name}'s wife {node.metadata.spouse.name}{' '}
                      </Button>
                    </>
                  ) : (
                    ' '
                  )}
                  &nbsp;&nbsp;&nbsp;
                  {isSpouseFamilyAdded(node) ? (
                    <Button
                      style={{ borderColor: 'red', backgroundColor: 'red', padding: '0.4rem 0.6rem' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSearchObject({ searchString: '', searchPeehar: node.spouse.uid });
                      }}
                    >
                      {node.gender === 'male' ? 'पीहर' : 'ससुराल'}
                    </Button>
                  ) : (
                    ''
                  )}
                  &nbsp;
                  {node.uid === path[0]
                    ? `( ${node.subcaste ? node.subcaste : node.gautra} परिवार - ${node.village} )`
                    : ''}
                </p>
                {/* <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='add' onClick={(e) => { e.preventDefault(); e.stopPropagation(); insertNewNode(path) }}> Add Son </Button>
                    <Button appearance="outline" shape={'Round'} size={'Small'} size='mini' Status={'Info'} circular icon='trash' onClick={(e) => { e.preventDefault(); e.stopPropagation(); removeNode(path) }} > Add Daughter </Button> */}
              </>
            ),
          };
        }}
      />
    </>
  );

  //function used for modal only

  const serchforMatchingUid = (obj, id) => {
    if (obj.uid == id) {
      return obj;
    }
    let children = obj['children'] || [];
    for (let i = 0; i < children.length; i++) {
      let result = serchforMatchingUid(children[i], id);
      if (result) {
        return result;
      }
    }
    return false;
  };
  const addRelationFromSuggestedData = async (matching_uid, node, change, relationObj) => {
    const familyInfoResponse = await getFamilyInfo(matching_uid);

    const selectedobj = serchforMatchingUid(familyInfoResponse?.result, matching_uid);
    if (selectedobj) {
      setNodeToBeAdded(node);
      setNodeToBeUpdated(selectedobj);
      setSuggestedChange(change);
      setSuggestedRelationObj(relationObj);
      setOpen(true);
    } else {
      toast.error('got error while fetching data from API');
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner message="Loading Family Tree" />
      ) : (
        <div>
          {isOpen ? (
            <AddSuggestedRelations
              onClose={() => {
                setOpen(false);
              }}
              nodeToBeAdded={nodeToBeAdded}
              nodeToBeUpdated={nodeToBeUpdated}
              relationObj={suggestedRelationObj}
              getFamilyDetails={getFamilyDetails}
              suggestedChange={suggestedChange}
            />
          ) : (
            ''
          )}
          <div className="outercomponent">
            <div id="leftsection">{sortableTreeDiv}</div>
            <div id="rightsection">
              <NodeDetails
                selectedNode={selectedNode}
                treeData={treeData}
                getFamilyDetails={getFamilyDetails}
                selectedPath={selectedPath}
                updatePage={() => {
                  getFamilyDetails(selectedNode.uid, [selectedNode.uid]);
                }}
                addRelationFromSuggestedData={addRelationFromSuggestedData}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
